import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Menu from "../components/menu_compuesto"

import '../sass/pages/cliente.scss'
import '../sass/app/menu_version_2.scss'

const Cliente = () => (
  <Layout>
		<Seo title="Soy Cliente" />
        <Menu />

        <div className="cliente">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-3 mb-md-0">
                        <StaticImage src="../images/phone-cliente.png" quality={100} formats={["AUTO", "WEBP", "AVIF"]} alt="Soy cliente"></StaticImage>
                    </div>
                    <div className="col-12 col-md-6 offset-lg-1">
                        <h3 className="titulo mb-5">Soy Cliente</h3>
                        <p>Consigue servicios en tu comunidad de forma simple y segura con sólo un click.</p>
                        <p>Semple te ahorra tiempo y esfuerzo ubicando a la persona ideal para solucionar tu problema.</p>
                        <p>Describe el servicio que necesitas, cuando y donde lo quieres, y en minutos elige la mejor oferta para ti, considerando calificación, precio, tiempo de atención y distancia.</p>
                        <p className="mb-5">Semple es la nueva App de que soluciona tu día.</p>

                        <a href="https://play.google.com/store/apps/details?id=mx.semple.android.client">
                            <StaticImage src="../images/googleplay.jpg" quality={100} alt="Google Play" style={{marginRight: "10px"}}></StaticImage>
                        </a>
                        <a href="https://apps.apple.com/us/app/semple/id1533723509">
                            <StaticImage src="../images/appstore.jpg" quality={100} formats={["png"]} alt="App Store"></StaticImage>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg-azul">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-3 mb-md-0">
                        <h3 className="titulo text-white">Beneficios de ser Cliente Semple</h3>
                    </div>
                    <div className="col-12 col-md-6 offset-lg-1">
                        <p className="mb-5 text-white">Semple te ayuda a solucionar problemas de forma fácil, rápida y segura, enlazándote en tiempo real con prestadores de servicios dispuestos a atenderte, sin costo adicional para ti, ya que no les cobramos comisión a ellos por los servicios que te ofrezcan.</p>

                        <ul className="ps-3 text-white">
                            <li className="mb-3">Descarga la app Semple y regístrate</li>
                            <li className="mb-3">Publica tu solicitud</li>
                            <li className="mb-3">Recibe en minutos varias ofertas</li>
                            <li className="mb-3">Elige el que más te convenga</li>
                            <li className="mb-3">Contáctalo y la negociación queda entre ustedes.</li>
                            <li>Nosotros sólo los enlazamos, ustedes eligen.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

  </Layout>
)

export default Cliente
